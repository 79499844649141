<template>
  <div class="relative" v-on-click-outside="onClickOutside">
    <button
      class="text-primary  bg-white hover:bg-primary-100 font-semibold rounded-xl text-sm px-2 py-1.5 text-center inline-flex items-center disabled:opacity-70 border-primary-focus border-2"
      :disabled="loading"
      type="button"
      @click="onClick()"
    >
      <Loader :size="4" class="mr-2" v-if="loading" />
      <svg
      v-else
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        class="inline-block w-5 h-5 stroke-current"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M4 6h16M4 12h16M4 18h16"
        ></path>
      </svg>
      <span class="px-2"> More Options</span>
      <font-awesome-icon v-if="!show" icon="angle-down" class="w-5 h-5"></font-awesome-icon>
      <font-awesome-icon v-else icon="angle-up" class="w-5 h-5"></font-awesome-icon>
    </button>
    <!-- Dropdown menu -->
    <div
      class="z-20 bg-white divide-y divide-gray-100 rounded-lg shadow-lg w-44 overflow-y-auto absolute"
      :class="{ hidden: !show }"
    >
      <ul class="text-sm text-gray-900">
        <li v-for="option in options" :key="option.id">
          <div
            class="px-4 py-2 hover:bg-gray-100 cursor-pointer flex items-center"
            @click="onOptionClicked(option)"
          >
            {{ option.label }}
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
const Loader = () => import("@shared/loader");

export default {
  name: "more-options",
  components: {
    Loader,
  },
  data() {
    return {
      show: false,
    };
  },
  props: {
    options: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    onClick() {
      this.show = !this.show;
    },
    onClickOutside() {
      this.show = false;
    },
    onOptionClicked(item) {
      this.$emit("onOptionClicked", item);
      this.onClickOutside();
    },
  },
};
</script>

<style lang="scss" scoped>
.loader {
  border-color: #ffffff !important;
  border-bottom-color: #0d69d5 !important;
  border-width: 3px !important;
}
</style>
