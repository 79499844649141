import { render, staticRenderFns } from "./moreoptions.vue?vue&type=template&id=0ec0b6ae&scoped=true"
import script from "./moreoptions.vue?vue&type=script&lang=js"
export * from "./moreoptions.vue?vue&type=script&lang=js"
import style0 from "./moreoptions.vue?vue&type=style&index=0&id=0ec0b6ae&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0ec0b6ae",
  null
  
)

export default component.exports